import Dashboard from "@/pages/Dashboard/Dashboard.jsx";
import Live from "@/pages/Live/Live.jsx";

const routes = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/processor",
    component: Dashboard,
  },
  {
    path: "/live",
    component: Live,
  },
];
export default routes;
