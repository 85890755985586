import { TabsCom } from "@/pages/Dashboard/components/Tabs/Tabs.jsx";
import CardsSection from "@/pages/Dashboard/components/CardsSection/CardsSection.jsx";
import Charts from "@/pages/Dashboard/components/MidSection/Charts/Charts.jsx";
import { useStore } from "zustand";
import dashboardStore from "@/pages/Dashboard/dashboardStore.js";
import SkeletonWrapper from "@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx";

const Dashboard = () => {
  const { categoryWiseCountsWithTotal, counts, graphData, loading } =
    useStore(dashboardStore);
  const tabDataCount = [
    {
      value: "processors",
      component: (
        <div>
          {loading && counts.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"9rem"}
              count={"7"}
              className={"flex flex-wrap gap-6"}
            />
          ) : (
            <div className={"flex flex-wrap w-full gap-2"}>
              {categoryWiseCountsWithTotal?.processors?.map((item, index) => {
                return (
                  <CardsSection
                    id={"processor"}
                    key={index}
                    title={item.source}
                    total={item.countsToday}
                    count={item.countsToday}
                    processors={item?.processors?.length}
                    countsToday={item.countsToday}
                    countsYesterday={item.countsToday * 0.95}
                  />
                );
              })}
            </div>
          )}
        </div>
      ),
    },
    {
      value: "scrapers",
      component: (
        <div>
          {loading && counts.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"9rem"}
              count={"5"}
              className={"flex flex-wrap gap-6"}
            />
          ) : (
            <div className={"flex flex-wrap w-full gap-2"}>
              {categoryWiseCountsWithTotal?.scrapers &&
                Object.keys(categoryWiseCountsWithTotal?.scrapers).map(
                  (item, index) => {
                    return (
                      <CardsSection
                        id={"scraper"}
                        key={index}
                        title={item}
                        total={
                          categoryWiseCountsWithTotal.scrapers[item]
                            .yesterday_stats
                        }
                        count={categoryWiseCountsWithTotal.scrapers[item].stats}
                        processors={
                          categoryWiseCountsWithTotal.scrapers[item]?.processors
                            ?.length
                        }
                        countsToday={
                          categoryWiseCountsWithTotal.scrapers[item].stats
                        }
                        countsYesterday={
                          categoryWiseCountsWithTotal.scrapers[item].stats *
                          0.95
                        }
                      />
                    );
                  },
                )}
            </div>
          )}
        </div>
      ),
    },
  ];
  const tabDataGraph = [
    {
      value: "scraped",
      component: (
        <div className={"h-full rounded-lg"}>
          {loading && graphData.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"44vh"}
              count={"1"}
              className={"w-full"}
            />
          ) : (
            <Charts title={"Scraped"} data={graphData.scraped || []} />
          )}
        </div>
      ),
    },
    {
      value: "indexed",
      component: (
        <div className={"h-full rounded-lg"}>
          {loading && graphData.length === 0 ? (
            <SkeletonWrapper
              width={"100%"}
              height={"44vh"}
              count={"1"}
              className={"w-full"}
            />
          ) : (
            <Charts title={"Indexed"} data={graphData.indexed || []} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={"w-full h-full"}>
      <TabsCom
        data={tabDataCount}
        prefix={"Dashboard"}
        graphs={
          <div className={"flex flex-col w-full gap-6"}>
            {tabDataGraph.map((item, index) => (
              <div key={index}>{item.component}</div>
            ))}
          </div>
        }
      />
    </div>
  );
};
export default Dashboard;
