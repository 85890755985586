import {Button} from '@/components/ui/button.jsx'
import {LoaderCircle} from 'lucide-react'
import React from 'react'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper.jsx'

const ButtonWrapper = React.forwardRef(
    (
        {
            className,
            text,
            icon,
            onClick,
            variant,
            loading,
            disabled,
            size = 'default',
            children,
            tooltip = false,
            tooltipText,
            tooltipDelayDuration,
            tooltipClassName
        },
        ref
    ) => {
        const button = (
            <Button
                ref={ref}
                disabled={disabled}
                onClick={onClick}
                className={`${className} ${disabled && 'disabled:pointer-events-auto cursor-not-allowed'}`}
                variant={variant}
                size={size}
            >
                {loading ? (
                    <LoaderCircle size={25} className='mr-2 ml-4 animate-spin'/>
                ) : children ? (
                    children
                ) : (
                    <>
                        {icon && <span className={`${text ? 'mr-2' : ''}`}>{icon}</span>}
                        {text}
                    </>
                )}
            </Button>
        )

        return tooltip ? (
            <TooltipWrapper
                component={button}
                text={tooltipText}
                delayDuration={tooltipDelayDuration}
                tooltipClassName={tooltipClassName}
            />
        ) : (
            button
        )
    }
)

ButtonWrapper.displayName = 'ButtonWrapper'

export default ButtonWrapper
