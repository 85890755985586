import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from '@/components/ui/tooltip.jsx'

export default function TooltipWrapper({
                                           component,
                                           text,
                                           delayDuration,
                                           tooltipClassName = '',
                                           side = 'top'
                                       }) {
    return (
        <TooltipProvider delayDuration={delayDuration}>
            <Tooltip>
                <TooltipTrigger>{component}</TooltipTrigger>
                <TooltipContent side={side} className={tooltipClassName}>
                    {text}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
