import axios from "axios";

const baseUrl = "https://api-processor.goloti.com";

const API = axios.create({ baseURL: baseUrl });

API.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
    }
    return Promise.reject(error);
  },
);

export default API;
