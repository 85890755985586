const MetaIcon = ({ fill = "#71717a", ...rest }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.41309 9.35312V16H6.03809V9.35312H8.74121L9.30371 6.29688H6.03809V5.21562C6.03809 3.6 6.67246 2.98125 8.30996 2.98125C8.81934 2.98125 9.22871 2.99375 9.46621 3.01875V0.246875C9.01934 0.125 7.92559 0 7.29434 0C3.95371 0 2.41309 1.57812 2.41309 4.98125V6.29688H0.350586V9.35312H2.41309Z"
        fill={fill}
      />
    </svg>
  );
};
export default MetaIcon;
