import { create } from "zustand";

const dashboardStore = create((set, get) => ({
  counts: [],
  setCounts: (counts) => set({ counts }),

    loading: false,
  setLoading: (loading) => set({ loading }),

  categoryWiseCounts: [],
  setCategoryWiseCounts: (categoryWiseCounts) => set({ categoryWiseCounts }),

  categoryWiseCountsWithTotal: {
    scrapers: [],
    processors: [],
  },
  setCategoryWiseCountsWithTotal: (key, categoryWiseCountsWithTotal) =>
    set((state) => ({
      categoryWiseCountsWithTotal: {
        ...state.categoryWiseCountsWithTotal,
        [key]: categoryWiseCountsWithTotal,
      },
    })),

  liveCounts: [],
  setLiveCounts: (liveCounts) => set({ liveCounts }),

  countsScrapers: [],
  setCountsScrapers: (countsScrapers) => set({ countsScrapers }),

  graphData: [],
  setGraphData: (graphData) => set({ graphData }),

  countsLoading: false,
  setCountsLoading: (countsLoading) => set({ countsLoading }),
}));
export default dashboardStore;
