import { useEffect, useState } from "react";

export const useCounts = ({ setHistory, endCount, setRate }) => {
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();

      setHistory((prevHistory) => {
        const newHistory = [
          ...prevHistory,
          { timestamp: currentTime, count: endCount },
        ];

        // Set the initial data if it hasn't been set yet
        if (!initialData) {
          setInitialData({ timestamp: currentTime, count: endCount });
        } else {
          // Calculate time elapsed in minutes since the first data point
          const minutesElapsed =
            (currentTime - initialData.timestamp) / (60 * 1000);

          // Calculate the difference in count since the first data point
          const countDiff = endCount - initialData.count;

          // Calculate the average rate (count per minute)
          const avgRate = countDiff / minutesElapsed;
          setRate(avgRate);
        }
        return newHistory;
      });
    }, 5000); // Update every 5 seconds for a more stable rate

    return () => clearInterval(interval);
  }, [endCount, initialData, setHistory, setRate]);
};
