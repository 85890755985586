import { useEffect } from "react";

export const useAccordion = ({
  expandAll,
  setExpandedItems,
  categoryWiseCounts,
}) => {
  useEffect(() => {
    if (!categoryWiseCounts) return;
    if (expandAll) {
      setExpandedItems(Object.keys(categoryWiseCounts));
    }
  }, []);
};

export const useAccordionItem = ({
  categoryWiseCounts,
  expandedItems,
  setExpandAll,
}) => {
  useEffect(() => {
    if (!categoryWiseCounts) return;
    if (expandedItems.length === Object.keys(categoryWiseCounts).length) {
      setExpandAll(true);
    } else if (expandedItems.length === 0) {
      setExpandAll(false);
    }
  }, [expandedItems]);
};
