const LotiLogo = ({ fill, ...rest }) => (
  <svg
    width="41"
    height="20"
    viewBox="0 0 41 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_227_20)">
      <path
        d="M0.670778 0H4.57032C4.47109 0.272031 4.42384 0.559589 4.43059 0.848496V17.9203H10.6367L11.0262 17.8116V19.6679H0.499985C0.624862 19.4026 0.681565 19.1124 0.66604 18.8214V0.870094C0.677515 0.579163 0.620812 0.289589 0.499985 0.0229585L0.670778 0Z"
        fill={fill}
      />
      <path
        d="M18.9515 3.8172C21.2513 3.8172 23.0516 4.54689 24.3516 6.0056C25.6524 7.4643 26.3018 9.4961 26.3004 12.101C26.3004 14.4872 25.6321 16.4001 24.2963 17.8399C22.9604 19.2804 21.1791 20 18.9515 20C16.6862 20 14.8947 19.2717 13.5784 17.8143C12.2621 16.3569 11.6026 14.392 11.6013 11.9194C11.6013 9.4421 12.2601 7.47713 13.5784 6.0245C14.8967 4.57187 16.6875 3.8361 18.9515 3.8172ZM15.3051 11.91C15.2916 13.1263 15.4597 14.338 15.806 15.5058C16.1408 16.5905 16.5626 17.3607 17.0709 17.8163C17.5853 18.2726 18.259 18.5163 18.9515 18.4981C20.6019 18.4981 21.6266 17.6685 22.0255 16.0107C22.3806 14.6694 22.5817 13.2944 22.6249 11.91C22.6405 10.6841 22.4717 9.46303 22.1248 8.2858C21.7893 7.20105 21.3627 6.42613 20.8449 5.96037C20.3292 5.49799 19.6508 5.24891 18.9515 5.26511C17.3355 5.26511 16.3237 6.11226 15.916 7.80722C15.5602 9.14847 15.3564 10.5242 15.3098 11.91H15.3051Z"
        fill={fill}
      />
      <path
        d="M31.8706 5.74168L33.6101 5.75585H35.38V4.14526H32.8474V1.85292C32.846 1.53161 32.8831 1.21097 32.9581 0.897766L29.367 1.3696V4.15471H27.0841V5.76462H29.367V17.0833C29.367 18.9571 30.3829 19.894 32.4147 19.894C33.4366 19.867 34.4444 19.6456 35.38 19.2406V17.4937C34.9736 17.7731 34.4957 17.9365 33.9996 17.9655C33.2301 17.9655 32.8474 17.5193 32.8474 16.6283V6.96075C32.8474 6.76432 32.7738 6.57532 32.6408 6.42951C32.5078 6.28304 32.3242 6.18988 32.1258 6.16828L31.8564 6.1352H31.8396C31.7903 6.12778 31.7457 6.10213 31.7147 6.06433C31.683 6.02585 31.6674 5.97725 31.6708 5.92865C31.6742 5.88005 31.6965 5.83415 31.7322 5.8004C31.7687 5.76664 31.8159 5.74707 31.8659 5.74639L31.8706 5.74168Z"
        fill={fill}
      />
      <path
        d="M39.1074 0.0539856C40.3711 0.0539856 41.0015 0.525151 41.0002 1.46882C41.0002 2.35984 40.2394 2.80535 38.7173 2.80535C37.457 2.80535 36.8259 2.35038 36.8246 1.44047C36.8232 0.529871 37.5839 0.0681609 39.1074 0.0539856ZM40.666 5.04842C40.6714 4.83916 40.6971 4.63194 40.743 4.42809C40.7457 4.41459 40.7444 4.39973 40.739 4.38691C40.7329 4.37408 40.7234 4.36328 40.7113 4.35653C40.6991 4.34911 40.6843 4.34641 40.6701 4.34776C40.6559 4.34911 40.6431 4.35518 40.6323 4.36396C40.2664 4.67851 39.6252 4.83579 38.7078 4.83579C38.0362 4.83579 37.5428 4.70686 37.2289 4.449C37.2174 4.44023 37.2046 4.4355 37.1904 4.43415C37.1762 4.43348 37.1621 4.43685 37.1506 4.44428C37.1384 4.4517 37.129 4.46251 37.1236 4.47466C37.1182 4.48749 37.1175 4.50166 37.1202 4.51516C37.1594 4.68729 37.1789 4.86279 37.1783 5.03897V18.8187C37.1823 19.1083 37.1249 19.3958 37.0095 19.6632H40.7693C40.689 19.3884 40.6505 19.1042 40.6559 18.8187L40.666 5.04842Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_227_20">
        <rect
          width="40.5002"
          height="20"
          fill={fill}
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default LotiLogo;