import {XAxis, YAxis, Legend, Tooltip, Area, AreaChart} from "recharts";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card.jsx";
import {ChartContainer} from "@/components/ui/chart.jsx";
import {formatNumber} from "@/pages/Dashboard/utils.js";
import {formatDate} from "@/lib/utils.js";
import _ from "lodash/fp.js";
import IconsMap from "@/components/IconsMap/IconsMap.jsx";

const transformData = (data) => {
    if (!data || typeof data !== "object") {
        return [];
    }

    return Object.keys(data)
        .map((date) => {
            const {total, ...rest} = data[date];
            return {date, ...rest};
        })
        .reverse();
};

const generateChartConfig = (data) => {
    if (!data || data.length === 0) {
        return {};
    }
    const colors = [
        "#8884d8",
        "#82ca9d",
        "#ffc658",
        "#ff8042",
        "#8dd1e1",
        "#d4a8ff",
        "#ffb3ba",
        "#ffd966",
        "#a4de6c",
        "#ffab91",
    ];
    let colorIndex = 0;

    return Object.keys(data[0])
        .filter((key) => key !== "date" && key !== "total")
        .reduce((config, key) => {
            config[key] = {
                label: _.upperFirst(key),
                color: colors[colorIndex % colors.length],
            };
            colorIndex++;
            return config;
        }, {});
};

const LegendRender = ({payload}) => {
    return (
        <ul
            className={
                "flex flex-row gap-4 justify-center items-center text-muted-foreground pt-2"
            }
        >
            {payload.map((entry, index) => (
                <li key={`item-${index}`} className="flex items-center gap-2">
                    <div
                        style={{backgroundColor: entry.color, width: 12, height: 12}}
                        className="inline-block rounded-sm"
                    />
                    <span>{_.upperFirst(entry.value)}</span>
                </li>
            ))}
        </ul>
    );
};

const TooltipRender = ({active, payload}) => {
    if (active && payload && payload.length) {
        const sortedData = payload.sort((a, b) => b.value - a.value);
        return (
            <div className="border rounded-md flex flex-col gap-2 p-4 shadow-md bg-background">
                {sortedData?.map((entry, index) => (
                    <div
                        key={`item-${index}`}
                        className="flex gap-2 flex-row items-center"
                    >
                        {IconsMap[entry.dataKey]({height: "20px", width: "20px"})}
                        <p style={{color: entry.color}}>{_.upperFirst(entry.dataKey)}</p>
                        <p className={"text-muted-foreground"}>
                            {formatNumber(entry.value)}
                        </p>
                    </div>
                ))}
            </div>
        );
    }

    return null;
};

function Charts({title, data}) {
    const transformedData = transformData(data);
    const chartConfig = generateChartConfig(transformedData);

    const startDate = transformedData.length > 0 ? transformedData[0].date : "";
    const endDate =
        transformedData.length > 0
            ? transformedData[transformedData.length - 1].date
            : "";
    const formattedStartDate = startDate
        ? formatDate(startDate, "d MMMM yyyy")
        : "";
    const formattedEndDate = endDate ? formatDate(endDate, "d MMMM yyyy") : "";
    return (
        <Card>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
                <CardDescription>{`${formattedStartDate} - ${formattedEndDate}`}</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig} className={"w-full h-[18rem]"}>
                    <AreaChart
                        data={transformedData}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                        height={100}
                    >
                        <defs>
                            {Object.keys(chartConfig).map((key) => (
                                <linearGradient
                                    key={key}
                                    id={`color${_.upperFirst(key)}`}
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor={chartConfig[key].color}
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor={chartConfig[key].color}
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            ))}
                        </defs>
                        <XAxis
                            dataKey="date"
                            tickLine={true}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(date) => formatDate(date, "dd-MMM")}
                        />
                        <YAxis tickFormatter={formatNumber}/>
                        <Tooltip content={TooltipRender}/>
                        <Legend
                            iconType={"circle"}
                            formatter={_.upperFirst}
                            content={LegendRender}
                        />
                        {Object.keys(chartConfig).map((key) => (
                            <Area
                                key={key}
                                dataKey={key}
                                type="monotone"
                                stroke={chartConfig[key].color}
                                fill={`url(#color${_.upperFirst(key)})`}
                                strokeWidth={2}
                            />
                        ))}
                    </AreaChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}

export default Charts;
