import {clsx} from "clsx";
import {twMerge} from "tailwind-merge";
import {DateTime} from "luxon";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export const formatDate = (date, format) => {
    return DateTime.fromFormat(date, "dd-MM-yyyy").toFormat(format);
};
export const getTimeFromIsoString = (isoDate) => {
    return DateTime.fromISO(isoDate, {zone: "utc"})
        .setZone("Asia/Kolkata")
        .toFormat("h:mma")
        .toUpperCase();
};

export const getRelativeTimeDifference = (isoDate) => {
    const now = DateTime.now();
    const date = DateTime.fromISO(isoDate, {zone: "utc"});

    return date.toRelative({base: now, style: "narrow"});
}
