import { DateTime } from "luxon";

export const timeGap30Minutes = (time) => {
  const currentTime = DateTime.now().setZone("Asia/Kolkata");
  const downloadStatusTime = DateTime.fromISO(time, { zone: "utc" }).setZone(
    "Asia/Kolkata",
  );
  const timeDifferenceMinutes = currentTime.diff(
    downloadStatusTime,
    "minutes",
  ).minutes;

  return timeDifferenceMinutes > 30;
};
