import * as actionTypes from "./actionTypes.js";
import dashboardStore from "@/pages/Dashboard/dashboardStore.js";
import _ from "lodash/fp.js";
import {
  getLiveCounts,
  processCategoryWiseCounts,
  processScrapersWithTotal,
} from "@/pages/Dashboard/utils.js";
const initialState = {
  error: false,
  loading: false,
  stats: null,
  errorMessage: "",
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_COUNTS:
      dashboardStore.getState().setLoading(true);
      dashboardStore.getState().setCountsLoading(true);
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_DASHBOARD_COUNTS_SUCCESS: {
      dashboardStore.getState().setLoading(false);
      dashboardStore.getState().setCountsLoading(false);
      dashboardStore.getState().setCounts(action.payload);
      const groupedData = _.flow(
        _.groupBy((item) => item.id.split("-")[0]),
        _.mapValues((items) => ({
          items,
          totalProcessedToday: items.reduce(
            (acc, item) => acc + (Number(item.processed?.today) || 0),
            0,
          ),
        })),
        _.toPairs,
        _.sortBy(([, group]) => -group.totalProcessedToday),
        _.fromPairs,
      )(action.payload.processor);

      const categoryWiseCounts = processCategoryWiseCounts(groupedData);
      const liveCounts = getLiveCounts(action.payload.processor);

      const scrapersCountWithLive = processScrapersWithTotal(
        action.payload.scraper,
      );
      dashboardStore.getState().setLiveCounts(liveCounts);
      dashboardStore.getState().setCategoryWiseCounts(groupedData);
      dashboardStore
        .getState()
        .setCategoryWiseCountsWithTotal("processors", [
          liveCounts,
          ...categoryWiseCounts,
        ]);
      dashboardStore
        .getState()
        .setCategoryWiseCountsWithTotal("scrapers", scrapersCountWithLive);
      return {
        ...state,
        loading: false,
        stats: action.payload.processor,
      };
    }
    case actionTypes.GET_DASHBOARD_COUNTS_FAILURE:
      dashboardStore.getState().setLoading(false);
      dashboardStore.getState().setCountsLoading(false);

      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.processor,
      };
    case actionTypes.GET_GRAPH_DATA:
      dashboardStore.getState().setLoading(true);
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_GRAPH_DATA_SUCCESS:
      dashboardStore.getState().setLoading(false);
      dashboardStore.getState().setGraphData(action.payload);
      return {
        ...state,
        loading: false,
        stats: action.payload,
      };
    case actionTypes.GET_GRAPH_DATA_FAILURE:
      dashboardStore.getState().setLoading(false);
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
