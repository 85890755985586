import TikTokIcon from "@/assets/icons/TikTokIcon.jsx";
import InstagramIcon from "@/assets/icons/InstagramIcon.jsx";
import MetaIcon from "@/assets/icons/MetaIcon.jsx";
import PinterestIcon from "@/assets/icons/PinterestIcon.jsx";
import YoutubeIcon from "@/assets/icons/YoutubeIcon.jsx";
import AdultIcon from "@/assets/icons/AdultIcon.jsx";
import LiveIcon from "@/assets/icons/LiveIcon.jsx";
import RedditIcon from "@/assets/icons/RedditIcon.jsx";
import EtsyIcon from "@/assets/icons/EtsyIcon.jsx";

const IconsMap = {
  tiktok: (props) => <TikTokIcon {...props} />,
  facebook: (props) => <MetaIcon {...props} />,
  instagram: (props) => <InstagramIcon {...props} />,
  pinterest: (props) => <PinterestIcon {...props} />,
  youtube: (props) => <YoutubeIcon {...props} />,
  adult: (props) => <AdultIcon {...props} />,
  live: (props) => <LiveIcon {...props} />,
  reddit: (props) => <RedditIcon {...props} />,
  etsy: (props) => <EtsyIcon {...props} />,
};

export default IconsMap;
