const EtsyIcon = ({ width = "30", height = "30" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="etsy"
      width={width}
      height={height}
    >
      <path d="M21 15.812v.001z"></path>
      <path
        fill="#F27224"
        d="M1.75 22.634V24c7.933-.254 13.379-.231 19.672 0 .093-1.179.734-6.491.828-7.071h-1.188c-1.719 4.004-1.875 5.344-5.272 5.344h-4.765c-1.601 0-2.362-.656-2.362-2.049v-7.447c3.55 0 4.698.107 4.698.107 1.625.025 1.62 1.148 2.109 3.295h1.375c-.115-5.273-.142-3.155.093-8.585h-1.375c-.704 3.04-.587 3.444-2.175 3.496 0 0-1.627.16-4.724.134V2.451c0-.335.027-.522.574-.522h7.314c1.921 0 2.332 2.553 2.922 4.848h1.241s.227-4.728.413-6.777C15.413.659 5.694.328 1.75.201v1.366c2.608.495 3.201.405 3.256 1.929.188 4.171.096 13.376 0 17.25-.019 1.459-.829 1.409-3.256 1.888z"
      ></path>
    </svg>
  );
};
export default EtsyIcon;
