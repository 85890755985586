import { useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.jsx";
import { cn } from "@/lib/utils.js";

export function AccordionWrapper({
  title,
  content,
  expandAll,
  expandedItems,
  setExpandedItems,
  name,
}) {
  useEffect(() => {
    if (expandAll) {
      setExpandedItems((prev) => [...prev, name]);
    } else {
      setExpandedItems([]);
    }
  }, [expandAll, name]);

  const toggleItem = (item) => {
    setExpandedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  return (
    <Accordion
      type="multiple"
      collapsible
      className="w-full"
      value={expandedItems}
      onValueChange={setExpandedItems}
    >
      <AccordionItem
        value={name}
        className={cn("px-4 rounded-md", {
          "bg-gray-100": expandedItems.includes(name),
        })}
      >
        <AccordionTrigger
          onClick={() => toggleItem(name)}
          className="w-full text-xl text-foreground flex justify-start gap-2"
        >
          {title}
        </AccordionTrigger>
        <AccordionContent className="mt-2">{content}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
