import ProcessorsCard from "@/pages/Live/components/ProcessorsCard/ProcessorsCard.jsx";
import { useStore } from "zustand";
import dashboardStore from "@/pages/Dashboard/dashboardStore.js";
import _ from "lodash/fp.js";
import { timeGap30Minutes } from "@/pages/Live/utils.js";
import { AccordionWrapper } from "@/components/common/Wrappers/Accordion/AccordionWrapper.jsx";
import liveStore from "@/pages/Live/liveStore.js";
import { useAccordion, useAccordionItem } from "@/pages/Live/useAccordion.js";
import { cn } from "@/lib/utils.js";
import SkeletonWrapper from "@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx";
import IconsMap from "@/components/IconsMap/IconsMap.jsx";
import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

const TabContent = ({ tab, loading }) => {
  const { categoryWiseCounts, counts } = useStore(dashboardStore);
  const { expandAll, setExpandAll } = useStore(liveStore);
  const [expandedItems, setExpandedItems] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const countUpRef = useRef(null);

  const calculateTotalProcessedToday = (data) => {
    return data.reduce(
      (acc, item) => acc + (Number(item.processed?.today) || 0),
      0,
    );
  };

  const filterData = (data) => {
    return data.filter((item) => {
      const isWarning =
        item?.queue.queue > 0 &&
        timeGap30Minutes(JSON.parse(item?.queue?.downloaderStatus)?.time);

      const isAlert = item?.download_toggle === "0";

      if (tab === "all") {
        return true;
      } else if (tab === "alert") {
        return isAlert;
      } else if (tab === "warning") {
        return isWarning;
      }
      return false;
    });
  };
  useAccordion({ expandAll, setExpandedItems, categoryWiseCounts });
  useAccordionItem({
    expandedItems,
    categoryWiseCounts,
    setExpandAll,
  });

  useEffect(() => {
    const totalProcessedToday = calculateTotalProcessedToday(
      categoryWiseCounts[tab] || [],
    );
    setCurrentCount(totalProcessedToday);
  }, [categoryWiseCounts, tab]);
  return (
    <div className={"flex flex-wrap gap-6 w-full"}>
      {loading && counts.length === 0 ? (
        <div className={"w-full"}>
          <SkeletonWrapper
            width={"100%"}
            height={"6rem"}
            count={5}
            className={"flex gap-4 flex-col"}
          />
        </div>
      ) : (
        Object.keys(categoryWiseCounts).map((category, index) => {
          const filteredData = filterData(categoryWiseCounts[category].items);
          if (filteredData.length === 0) {
            return null;
          }
          const totalProcessedToday =
            calculateTotalProcessedToday(filteredData);
          return (
            <AccordionWrapper
              key={index}
              content={
                <div key={index} className={""}>
                  <div className={"grid grid-cols-3 gap-5"}>
                    {filteredData.map((item) => (
                      <ProcessorsCard key={item.id} item={item} />
                    ))}
                  </div>
                </div>
              }
              title={
                <div className={"flex items-center justify-between w-full"}>
                  <div className={"flex items-center gap-2 font-semibold px-2"}>
                    <div className={"flex items-center gap-2"}>
                      <div
                        className={cn(
                          "w-[3rem] h-[3rem] bg-muted text-muted-foreground rounded-full flex items-center justify-center shadow-md",
                          {
                            "bg-background": expandedItems.includes(category),
                          },
                        )}
                      >
                        {IconsMap[category]()}
                      </div>
                      <p>{_.upperFirst(category)}</p>
                    </div>
                    <div className={"text-foreground"}>
                      ({filteredData.length})
                    </div>
                  </div>
                  <div className={"text-foreground font-semibold"}>
                    <CountUp
                      ref={countUpRef}
                      start={currentCount}
                      end={totalProcessedToday}
                      duration={10}
                      separator=","
                      preserveValue={true}
                    />
                  </div>
                </div>
              }
              expandAll={expandAll}
              setExpandAll={setExpandAll}
              expandedItems={expandedItems}
              setExpandedItems={setExpandedItems}
              name={category}
            />
          );
        })
      )}
    </div>
  );
};

export default TabContent;
