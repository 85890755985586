import {Link, useLocation} from "react-router-dom";
import {cn} from "@/lib/utils.js";
import {BrainCircuit, ChartBar, LockOpen, ServerCog} from "lucide-react";
import LotiLogo from "../../assets/Logo.jsx";
import {Separator} from "@/components/ui/separator.jsx";
import ButtonWrapper from "@/components/common/Wrappers/Button/ButtonWrapper.jsx";

const Navbar = () => {
    const navItems = [
        {
            name: "Dashboard",
            link: "/",
            icon: <ChartBar className={"w-[18px]"}/>,
        },
        {
            name: "Live",
            link: "/live",
            icon: <ServerCog className={"w-[18px]"}/>,
        },
        {
            name: "Processors",
            link: "/processor",
            icon: <BrainCircuit className={"w-[18px]"}/>,
        },
    ];

    const location = useLocation();
    const currentPath = location.pathname;
    return (
        <div
            className={
                "w-full h-full flex items-center gap-10 px-20 font-medium bg-navbar text-background"
            }
        >
            <div className={"flex items-center justify-between w-[10rem]"}>
                <div className={"h-full flex items-center justify-center"}>
                    <LotiLogo fill={"#079B48"}/>
                </div>
                <div className={"h-[1.2rem]"}>
                    <Separator orientation="vertical" className={"h-full bg-gray-600"}/>
                </div>
                <div className={"text-sm font-light text-text-muted"}>Processors</div>
            </div>
            <div className={"w-full flex items-center justify-between"}>
                <div className={"flex gap-2 font"}>
                    {navItems.map((item) => (
                        <div
                            key={item.link}
                            className={"p-2 rounded-lg text-background text-sm"}
                        >
                            <Link
                                to={item.link}
                                className={cn(
                                    "flex gap-1 items-center px-4 py-2 rounded-lg font-normal hover:bg-muted-navbar transition-all duration-300 ease-in-out",
                                    {
                                        "bg-muted-navbar": currentPath === item.link,
                                    },
                                )}
                            >
                                <p>{item.icon}</p>
                                <p>{item.name}</p>
                            </Link>
                        </div>
                    ))}
                </div>
                <div>
                    <ButtonWrapper
                        text={"Unlock"}
                        className={
                            "bg-blue-500 hover:bg-blue-500/80 rounded-full font-light"
                        }
                        icon={<LockOpen className={"w-[18px]"}/>}
                    />
                </div>
            </div>
        </div>
    );
};
export default Navbar;
