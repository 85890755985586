const TikTokIcon = ({ width = "35", height = "35" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="TikTok"
      style={{ mixBlendMode: "multiply" }}
      viewBox="0 0 512 512"
      id="tiktok"
      width={width}
      height={height}
    >
      <rect
        width="512"
        height="512"
        fill="#fff"
        rx="15%"
        style={{ mixBlendMode: "multiply" }}
      ></rect>
      <path
        fill="#ff004f"
        d="m 219,200 a 117,117 0 1 0 100.6,115.4 v -128 a 150,150 0 0 0 88,28 v -63 a 88,88 0 0 1 -88,-88 H 256 V 316 a 53.5,53.5 0 1 1 -37,-51 z"
        style={{ mixBlendMode: "multiply" }}
        transform="translate(18 15)"
      ></path>
      <path
        fill="#00f2ea"
        d="m 219,200 a 117,117 0 1 0 100.6,115.4 v -128 a 150,150 0 0 0 88,28 v -63 a 88,88 0 0 1 -88,-88 H 256 V 316 a 53.5,53.5 0 1 1 -37,-51 z"
        style={{ mixBlendMode: "multiply" }}
      ></path>
    </svg>
  );
};
export default TikTokIcon;
