import { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import _ from "lodash/fp.js";
import { formatNumber } from "@/pages/Dashboard/utils.js";
import { ClockArrowUp, Cpu } from "lucide-react";
import { cn } from "@/lib/utils.js";
import { useCounts } from "@/pages/Dashboard/useCounts.js";
import IconsMap from "@/components/IconsMap/IconsMap.jsx";

const CardsSection = ({
  title,
  processors,
  countsToday,
  countsYesterday,
  id,
}) => {
  const [endCount, setEndCount] = useState(countsToday);
  const [startCount, setStartCount] = useState(countsYesterday);
  const [history, setHistory] = useState([]); // History of counts and timestamps
  const [rate, setRate] = useState(0); // Processing rate
  const countUpRef = useRef(null);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (hasStarted) {
      setEndCount(countsToday);
    } else {
      setStartCount(countsYesterday);
      setEndCount(countsToday);
      setHasStarted(true);
    }
  }, [countsToday, countsYesterday]);

  // Update rate every 3 seconds, based on the sliding window of 1 minute
  useCounts({ history, setHistory, endCount, setRate });

  return (
    <div
      className={cn(
        "w-full flex flex-row gap-4 items-center mx-5 px-0 py-4 border-b",
        {
          "border rounded-xl mx-0 px-6": title === "live",
        },
      )}
    >
      <div
        className={cn(
          "w-[4.5rem] h-[4rem] bg-muted text-muted-foreground rounded-full flex items-center justify-center shadow-md",
          {
            "bg-green-100/60": title === "live",
          },
        )}
      >
        {IconsMap[title]()}
      </div>
      <div className={"w-full"}>
        <div className={"flex justify-between items-center "}>
          <p className={"text-muted-foreground"}>{_.upperFirst(title)}</p>
          <div className={cn("flex items-center gap-2", {})}>
            <p className={"text-sm text-foreground"}>
              <ClockArrowUp className={"w-[20px]"} strokeWidth={1.5} />
            </p>
            <p className={"text-foreground pt-1"}>
              {formatNumber(Math.round(rate))} / min
            </p>
          </div>
        </div>
        <div
          className={
            "text-green-600 text-lg font-semibold flex justify-between items-center"
          }
        >
          <CountUp
            ref={countUpRef}
            start={startCount}
            end={endCount}
            duration={10}
            separator=","
            preserveValue={true}
          />
          {id === "processor" && title !== "live" && (
            <div className={"flex items-center gap-2 text-sm"}>
              <p className={"text-foreground"}>
                <Cpu className={"w-[20px]"} strokeWidth={1.5} />
              </p>
              <p className={"text-foreground pt-[2px]"}>{processors}</p>
              <p className={"text-foreground pt-[2px]"}>Processors</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
