import "./App.css";
import Navbar from "@/components/Navbar/Navbar.jsx";
import {Route, Routes} from "react-router-dom";
import routes from "@/routes/routes";
import {useEffect, useMemo} from "react";
import {getDashboardCounts, getGraphData} from "@/redux/Dashboard/actions.js";
import {useDispatch} from "react-redux";

const Container = ({children}) => {
    return (
        <div className={"flex justify-center py-6 w-full px-20"}>{children}</div>
    );
};

function App() {
    const iRoutes = useMemo(() => {
        return routes.map((route, index) => {
            const Component = route.component;
            return (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <Container>
                            <Component/>
                        </Container>
                    }
                />
            );
        });
    }, []);
    const dispatch = useDispatch();
    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(getDashboardCounts({id: "processors"}));
        }, 3000);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        dispatch(getDashboardCounts({id: "processors"}));
        dispatch(getGraphData());
    }, [dispatch]);

    return (
        <>
            <div className={"w-full flex justify-center h-[8vh] "}>
                <Navbar/>
            </div>
            <div className={"h-[92vh] overflow-hidden"}>
                <Routes>
                    <>{iRoutes}</>
                </Routes>
            </div>
        </>
    );
}

export default App;
