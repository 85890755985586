import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs.jsx";
import _ from "lodash/fp.js";
import {cn} from "@/lib/utils.js";
import {ScrollArea} from "@/components/ui/scroll-area.jsx";

export function TabsCom({data, prefix, graphs, actions}) {
    return (
        <Tabs defaultValue={data[0].value} className={"h-full"}>
            <div className={"flex items-center justify-between gap-5 mb-6 h-full"}>
                <div className={"flex items-start gap-5"}>
                    <div className={"text-2xl font-semibold"}>{prefix}</div>
                    <TabsList className="text-foreground bg-gray-200">
                        {data.map((item, index) => (
                            <TabsTrigger key={index} value={item.value} className={"px-4"}>
                                {_.upperFirst(item.value)}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </div>
                <div>{actions}</div>
            </div>
            <div className={cn({"grid grid-cols-3 gap-12 h-full": graphs})}>
                <ScrollArea className={"col-span-1 h-[80vh]"}>
                    {data.map((item, index) => (
                        <TabsContent key={index} value={item.value}>
                            {item.component}
                        </TabsContent>
                    ))}
                </ScrollArea>
                {graphs && (
                    <ScrollArea className={"col-span-2 w-full h-[80vh]"}>
                        <div className={""}>{graphs}</div>
                    </ScrollArea>
                )}
            </div>
        </Tabs>
    );
}
